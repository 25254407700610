import React from "react";
import { EMPTY_OBJECT } from "../../../../../assets/constants";
import ButtonDarkBlue from "../../../../globalComponents/molecules/ButtonDarkBlue";
import ConvertStringToHtml from "../../../../globalComponents/ConvertStringToHtml";
import "./CarrerAccordianRightCard.css";
import { colors } from "@mui/material";
const CarrerAccordianRightCard = ({ selectedPostion, handleForm }) => {
  //   const { Position_Description } = position?.attributes || EMPTY_OBJECT;
  return (
    <div
      style={{ background: "#F4F4F4", height: "600px", overflowY: "scroll" }}
      className="col-lg-8"
    >
      <div className="fullPositionDesc p-4">
        <ConvertStringToHtml htmlData={selectedPostion} />
      </div>
      <div className="d-flex justify-content-center align-items-center mb-4">
        <ButtonDarkBlue
          className="w-25 rounded-5 "
          name="Apply Now"
          onClick={handleForm}
        />
      </div>
    </div>
  );
};

export default CarrerAccordianRightCard;
