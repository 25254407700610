import Masonry from "@mui/lab/Masonry";
import MasonryCard from "./MasonryCard";
import useWindowSize from "../../hooks/useWindowSize";

const size = [
  "450",
  "490",
  "400",
  "420",
  "450",
  "450",
  "350",
  "400",
  "600",
  "450",
  "500",
  "550",
  "450",
  "550",
  "550",
  "600",
  "550",
];

const MasonryCards = ({ data, imgType }) => {
  //imgType if if have option of properties field like 'img1' field 'img2' field
  const [isMobileSize] = useWindowSize();
  const columnCount = isMobileSize < 576 ? 2 : 4;
  const spacing = isMobileSize < 576 ? 0.25 : 2;

  return (
    <div className="row">
      {data?.map((item, idx) => {
        return (
          <div className="col-md-6 col-lg-4 mt-4">
            <MasonryCard
              key={idx}
              item={{ item, size: size[idx % data.length] }}
              imgType={imgType}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MasonryCards;
