import { useLocation } from "react-router-dom";

// import InfiniteSlides from "./../../globalComponents/slickCrousel/InfiniteSlides";
import Heading from "../../globalComponents/molecules/Heading";
import EventLocationSummary from "./EventLocationSummary/EventLocationSummary";
import EventSideSummary from "./EventSideSummary";

import useApiFetcher from "../../../hooks/useApiFetcher";
import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
// import { EVENT_SLIDES } from "../../../assets/constants/events";
import "./eventOverview.css";
import { EMPTY_ARRAY } from "../../../assets/constants";

// const settings = {
//   dots: true,
//   infinite: true,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 1000,
//   speed: 1200,
//   lazyLoad: true,
//   initialSlide: 2,
// };

const url = (id) => getFullUrl(`${API_URL.EVENT}/${id}?populate=*`);

const EventOverview = () => {
  const location = useLocation();
  const eventId = location?.state;

  const [eventSummary, error, isLoading] = useApiFetcher(url(eventId));

  if (error) return <div>{error.message}</div>;
  if (isLoading) return <div>Loading Event...</div>;

  const {
    Title,
    Event_Description,
    Start_Date,
    Venue,
    Enter_The_Time,
    Featured_Image = EMPTY_ARRAY,
  } = eventSummary?.attributes || "";

  const img = Featured_Image?.data?.attributes?.url;

  return (
    <>
      <div className="container">
        <section>
          <div className="row">
            <div
              className="col-md-7"
              style={{ padding: "1.5rem 1rem 1rem 1rem" }}
            >
              <Heading
                text="Event Description"
                fontSize="1.5rem"
                fontWeight="700"
                lineHeight="1.5"
                className="text-black"
                style={{ padding: "9px 0px 0px 24px" }}
              />
              <EventSideSummary
                description={Event_Description}
                className="mt-2"
              />
            </div>

            <div className="col-md-5">
              <EventLocationSummary
                data={{ Start_Date, Venue, Enter_The_Time, img }}
              />
            </div>
          </div>
        </section>
        {/* <section className="my-5">
          <InfiniteSlides
            className="eventGallerySlider"
            settings={settings}
            data={EVENT_SLIDES}
          />
        </section> */}
      </div>
    </>
  );
};

export default EventOverview;
