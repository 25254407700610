import React, { useCallback, useState, useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FORM_NAMES } from "../../../assets/constants/formNames";
import { submissionOnZoho } from "../../../assets/constants/zoho";
import { PROCESS_MESSAGE } from "../../../assets/constants/messages";
import { MdKeyboardArrowDown } from "react-icons/md";

import ThankYouQuery2 from "./ThankYouQuery2";
import "./queryForm2.css";

const QueryForm2 = () => {
  const [formName] = useState(FORM_NAMES?.ANY_ENQUIRIES_FORM_NAME);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [propertyType, setPropertyType] = useState("Select Property Type");
  const [errors, setErrors] = useState({});
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const options = {
    "Job Enquiry": [],
    "Property Enquiry": ["Residential", "Commercial"],
    "Channel Partner Enquiry": [],
  };

  // Close menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const validateForm = () => {
    const validationErrors = {};

    if (!name.trim()) {
      validationErrors.name = "Full Name is required";
    }

    if (!phone.trim()) {
      validationErrors.phone = "Mobile Number is required";
    } else if (!/^\d{12}$/.test(phone.replace(/\D/g, ""))) {
      validationErrors.phone = "Mobile Number must be 10 digits";
    }

    if (propertyType === "Select Property Type") {
      validationErrors.propertyType = "Please select a property type";
    }

    return validationErrors;
  };

  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length === 0) {
        setIsBtnDisable(true);
        await submissionOnZoho({ formName, name, phone, propertyType });
        setIsBtnDisable(false);
        setIsSuccessfullySubmitted(true);
        setName("");
        setPhone("");
        setPropertyType("Select Property Type");
      } else {
        setErrors(validationErrors);
      }
    },
    [formName, name, phone, propertyType]
  );

  const handleThankYouComplete = () => setIsSuccessfullySubmitted(false);

  return (
    <div
      className="p-4"
      style={{
        backgroundColor: "rgba(38, 38, 38, 0.77)",
        borderRadius: "15px",
        width: "100%",
        color: "white",
      }}
    >
      <h2
        className="text-center mb-4"
        style={{ fontWeight: "bold", fontFamily: "Poppins" }}
      >
        Contact Us
      </h2>
      {isSuccessfullySubmitted ? (
        <ThankYouQuery2 onComplete={handleThankYouComplete} />
      ) : (
        <form onSubmit={handleFormSubmit}>
          <div>
            <input
              name="name"
              type="text"
              required
              className="border-bottom border-none text-white mb-3 pb-1 bg-transparent"
              value={name}
              placeholder="Name"
              style={{
                width: "100%",
                border: "none",
                borderBottom: "1px solid white",
              }}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <span className="alertMsg">{errors.name}</span>}
          </div>

          <div className="input-group">
            <PhoneInput
              className="border-bottom border-none text-white mb-3 pb-1"
              name="phone"
              country={"in"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              countryCodeEditable={false}
              placeholder=""
              style={{
                background: "none",
                width: "100%",
                border: "none",
                borderBottom: "1px solid white",
              }}
            />
            {errors.phone && (
              <span className="alertMsg my-1 mb-3">{errors.phone}</span>
            )}
          </div>

          {/* Nested List Selector for Property Type */}
          <div
            style={{ position: "relative", display: "inline-block" }}
            ref={menuRef}
            className=" w-100  border-none mb-3"
          >
            <div
              style={{
                padding: "5px 20px",
                background: "",
                color: "white",
                // borderRadius: "10px",
                // fontWeight: "bold",
                cursor: "pointer",
                width: "100%",
                textAlign: "center",
                marginBottom: "15px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid white",
              }}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {propertyType}
              <MdKeyboardArrowDown size={32} />
            </div>

            {isMenuOpen && (
              <div
                style={{
                  position: "absolute",
                  background: "white",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  marginTop: "0px",
                  minWidth: "100%",
                  zIndex: 10,
                  color: "black",
                }}
              >
                {Object.keys(options).map((category) => (
                  <div
                    key={category}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      borderBottom: "1px solid #eee",
                      position: "relative",
                    }}
                    onMouseEnter={() =>
                      options[category]?.length > 0 &&
                      setHoveredCategory(category)
                    }
                    onMouseLeave={() => setHoveredCategory(null)}
                    onClick={() => {
                      if (!options[category]?.length) {
                        setPropertyType(category);
                        setIsMenuOpen(false);
                      }
                    }}
                  >
                    {category} {options[category]?.length > 0 && "➤"}
                    {hoveredCategory === category &&
                      options[category]?.length > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            left: "100%",
                            top: "0",
                            // background: "white",

                            paddingLeft: "15px",
                          }}
                        >
                          <div
                            className="bg-white"
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              padding: "5px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                              minWidth: "150px",
                              zIndex: 20,
                              color: "black",
                            }}
                          >
                            {options[category].map((subCategory) => (
                              <div
                                key={subCategory}
                                style={{
                                  padding: "8px",
                                  cursor: "pointer",
                                  borderBottom: "1px solid #eee",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // setPropertyType(`${category} > ${subCategory}`);
                                  setPropertyType(subCategory);
                                  setIsMenuOpen(false);
                                }}
                              >
                                {subCategory}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {errors.propertyType && (
            <span className="alertMsg">{errors.propertyType}</span>
          )}

          <button
            type="submit"
            className="btn p-2 w-100"
            style={{
              backgroundColor: "#666",
              color: "white",
              fontWeight: "bold",
              borderRadius: "25px",
            }}
            disabled={isBtnDisable || !phone || !name}
          >
            {isBtnDisable
              ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
              : "Submit"}
          </button>
        </form>
      )}
    </div>
  );
};

export default QueryForm2;
