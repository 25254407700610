import TextBg from "../../../globalComponents/molecules/TextBg";
import Heading from "../../../globalComponents/molecules/Heading";
import PerkCard from "./PerkCard";

import "./perkAndBeneFits.css";

const PerkAndBeneFits = ({ data }) => {
  const {
    Benefits_Perks,
    Benefits_Perks_Heading,
    Benefits_And_Perks_Description,
  } = data;

  return (
    <div className="my-4 pb-3 pt-5">
      <Heading
        text={Benefits_Perks_Heading}
        color={"black"}
        className="fw-bold mt-3  text-center"
      />
      {/* <TextBg
        text={Benefits_Perks_Heading}
        fontWeight={800}
        fontSize="4rem"
        className="py-3"
      /> */}
      <div className="center-item mb-5 mb-md-0">
        <Heading
          className="text-container-description w-75 my-4"
          text={Benefits_And_Perks_Description}
          fontSize="1rem"
          color="#000"
        />
      </div>
      <div className="row my-3">
        {Benefits_Perks?.map((item) => (
          <div key={item?.id} className="col-lg-3 col-md-6">
            <PerkCard data={item} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default PerkAndBeneFits;
