import React, { useState } from "react";
import "./SubscribeSection.css";
import Esclipse from "../../../assets/image4.png";
import Esclipse1 from "../../../assets/Ellipse14.png";
import Esclipse2 from "../../../assets/Ellipse15.png";
import Esclipse3 from "../../../assets/Image3.png";
import { submissionOnZoho } from "../../../assets/constants/zoho";

const SubscribeSection = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [emailSubmitting, setEmailSubmitting] = useState(false);

  const validateForm = () => {
    const validationErrors = {};
    if (!email.trim()) {
      validationErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      validationErrors.email = "Invalid email format.";
    }
    return validationErrors;
  };

  const handleSubscribe = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        setEmailSubmitting(true);
        await submissionOnZoho({ email });
        setMessage("Thank You For Subscribing");
        setEmail("");
        setErrors({});
        setEmailSubmitting(false);
        setTimeout(() => setMessage(""), 1500);
      } catch (error) {
        setMessage("Subscription failed. Please try again.");
      }
    } else {
      setErrors(validationErrors);
      setEmailSubmitting(false);
    }
  };

  return (
    <section className="subscribe-section p-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 d-flex flex-column">
            <h2 className="fw-bold mb-3 text-center">
              Subscribe to Wealth Clinic
            </h2>
            <p className="mb-4 text-center">
              The latest news and updates from Wealth Clinic sent to your inbox.
            </p>
            <form style={{ width: "100%" }} onSubmit={handleSubscribe}>
              <div className="d-flex bg-white rounded-pill">
                <input
                  type="email"
                  className="form-control rounded-start-pill"
                  placeholder="Your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    padding: "10px 20px",
                    paddingRight: "100px",
                    width: "100%",
                    border: "none",
                  }}
                  required
                />
                <button
                  type="submit"
                  className="btn-orange rounded-pill"
                  style={{
                    padding: "10px 48px",
                    border: "none",
                  }}
                >
                  {emailSubmitting ? "Subscribing..." : "Subscribe"}
                </button>
              </div>
            </form>
            {errors.email && <p className="text-danger mt-2">{errors.email}</p>}
            {message && (
              <p className=" mt-2" style={{ color: "#ef750f" }}>
                {message}
              </p>
            )}
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="image-grid">
              <div className="row">
                <div className="col-6 mb-3">
                  <img
                    src={Esclipse}
                    alt="Building 1"
                    className="img-fluid "
                    style={{ marginTop: "22px" }}
                  />
                </div>
                <div className="col-6 mb-3">
                  <img
                    src={Esclipse1}
                    alt="Interior"
                    className="img-fluid mt-3"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <img src={Esclipse2} alt="Building 2" />
                </div>
                <div className="col-6">
                  <img
                    src={Esclipse3}
                    alt="Building 3"
                    className="img-fluid"
                    style={{ marginTop: "22px", marginLeft: "22px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscribeSection;
