import { FaSquareFacebook } from "react-icons/fa6";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useMemo } from "react";

import useApiFetcher from "../../../hooks/useApiFetcher";
import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
import { EMPTY_OBJECT } from "../../../assets/constants";
import "./socialMediaIcons.css";

const SocialMediaIcons = () => {
  const url = getFullUrl(API_URL.SOCIAL_MEDIA_ICONS);
  const [socialIcons, error, isLoading] = useApiFetcher(url);
  if (error) <div>{error.message}</div>;
  if (isLoading) <div>Loading....</div>;

  const { attributes = EMPTY_OBJECT } = socialIcons || {};

  const socialMediaWithLinks = useMemo(
    () => [
      {
        url: attributes?.Facebook_URL,
        icon: <FaSquareFacebook />,
        name: "Facebook",
      },
      {
        url: attributes?.WhatsApp_URL,
        icon: <FaWhatsappSquare />,
        name: "WhatsApp",
      },
      {
        url: attributes?.Twitter_URL,
        icon: <FaSquareXTwitter />,
        name: "Twitter",
      },
      {
        url: attributes?.Instagram_URL,
        icon: <FaInstagramSquare />,
        name: "Instagram",
      },
      {
        url: attributes?.Linkdin_URL,
        icon: <FaLinkedin />,
        name: "LinkedIn",
      },
      {
        url: attributes?.YouTube_URL,
        icon: <FaYoutube />,
        name: "YouTube",
      },
    ],
    [attributes]
  );

  if (!socialMediaWithLinks.length) {
    return null;
  }

  return (
    <div className="w-90 d-flex flex-wrap justify-content-between">
      {socialMediaWithLinks.map((socialIcon, idx) => {
        const { url, icon, name } = socialIcon || EMPTY_OBJECT;

        // Skip if no URL or icon is provided
        if (!url || !icon) return null;

        return (
          <Link
            to={url}
            key={idx}
            className="socialIconsFooter"
            target="_blank"
            aria-label={`Visit our ${name} page`}
          >
            {icon}
          </Link>
        );
      })}
    </div>
  );
};

export default SocialMediaIcons;
