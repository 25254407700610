import React, { memo, useState, useEffect } from "react";
import CareerAccordionCard from "../CareerAccordionCard/";
import CarrerAccordianRightCard from "./CarrerAccordianRightCard";

const CareerAccordionCardList = memo(
  ({ filteredPositions, callBackHandleJobForm }) => {
    const [selectedPostion, setSelectedPosition] = useState();
    const [selectedPostionName, setSelectedPositionName] = useState({});
    const [activeCard, setActiveCard] = useState(0);

    useEffect(() => {
      if (filteredPositions?.length > 0) {
        setSelectedPosition(
          filteredPositions[0]?.attributes?.Position_Description
        );
      }
    }, [filteredPositions]);

    return (
      <div className="row mt-5 align-items-center">
        <div
          className="col-lg-4 bg-white shadow p-4 rounded-4"
          style={{
            background: "#F4F4F4",
            height: "400px",
            overflowY: "auto",
          }}
        >
          {filteredPositions.map((position, idx) => {
            const { Position_Name } = position?.attributes;
            return (
              <CareerAccordionCard
                key={idx}
                index={idx}
                position={position}
                handleForm={() => callBackHandleJobForm(Position_Name)}
                setSelectedPosition={setSelectedPosition}
                setSelectedPositionName={setSelectedPositionName}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            );
          })}
        </div>

        <CarrerAccordianRightCard
          selectedPostion={selectedPostion}
          handleForm={() => callBackHandleJobForm(selectedPostionName)}
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.filteredPositions === nextProps.filteredPositions
);

export default CareerAccordionCardList;
