import Header from "../../globalComponents/header/Header";
import Heading from "../../globalComponents/molecules/Heading";
import HeaderMob from "../../mobileComponents/HeaderMob";
import AdvancedSearchBar from "../../advancedSearchBar/AdvancedSearchBar";
// import SearchBar from "../molecules/SearchBar";
// import MobileSearchBar from "../../mobileComponents/page/template/MobileSearchBar";
import abouts from "../../../assets/All_Directors_Team_ddb3d525b1.jpg";
import useDeviceSize from "../../../hooks/useDeviceSize";
import "./newTemplateSlider.css";
// import "./templateSlider.css";

const TemplateSlider = ({ searchBar, text, style, height, bgImage }) => {
  const isMobile = useDeviceSize();
  const isVideo =
    bgImage.endsWith(".mp4") ||
    bgImage.endsWith(".webm") ||
    bgImage.endsWith(".ogg");
  let newStyle = style;
  let heightIs = height ? height : isMobile ? "360px" : "100vh";

  return (
    <section className="container-fluid p-0">
      <div
        className="video-container"
        style={{
          height: heightIs,
          ...newStyle,
        }}
      >
        {/* <video autoPlay loop muted>
          <source src={VIDEO_URL} type="video/mp4" />
        </video> */}
        <div className="templateContainer">
          {isVideo ? (
            <video
              autoPlay
              loop
              muted
              playsInline
              loading="lazy"
              poster={abouts}
              preload="auto"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                filter: "brightness(50%)",
              }}
            >
              <source src={bgImage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <ImgCard imgHeight={height} bgImage={bgImage} />
          )}
        </div>
        <div className="content-on-top">
          <div className="header-container">
            {isMobile ? <HeaderMob /> : <Header />}
          </div>

          <div className="searchBar-container">
            {text && text !== "" ? (
              <Heading
                tag="h1"
                text={text}
                fontSize="2.5rem"
                fontWeight="900"
                color="#fff"
                className="text-center text-capitalize fs-1"
              />
            ) : null}

            {searchBar === "enabled" ? (
              <div className="d-flex justify-content-center">
                <AdvancedSearchBar />
              </div>
            ) : null}

            <p className="text-white text-center fs-lg">
              The Most Followed Real Estate Consultant in India
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TemplateSlider;

export const ImgCard = ({ imgHeight, bgImage }) => {
  return (
    <>
      <div className="templateSliderContainer">
        <div className="h-100">
          <img
            className="img-fluid"
            alt="wealth-clinic"
            title="wealth clinic template"
            src={bgImage}
            loading="lazy"
            style={{ height: imgHeight || "100vh", objectFit: "cover" }}
          />
        </div>
      </div>
    </>
  );
};
