import HeaderMob from "../../HeaderMob";
import Heading from "../../../globalComponents/molecules/Heading";
// import MobileSearchBar from "./MobileSearchBar";

const TemplateMob = ({ searchBar, text, bg }) => {
  return (
    <section className="container-fluid p-0">
      {/* image-container */}
      <div className="image-container">
        <div>
          <div className="header-container">
            <HeaderMob />
          </div>
          <div
            className="searchBar-container text-capitalize"
            style={{
              transform: "translate(-50%, 132%)",
            }}
          >
            {text && text !== "" ? <Heading tag="h1" text={text} /> : null}
            {/* {searchBar === "enabled" ? (
              <div className="d-flex justify-content-center">
                <div className="row height d-flex">
                  <div className="col-md-8">
                    <MobileSearchBar />
                  </div>
                </div>
              </div>
            ) : null} */}
            <p>The Most Followed Real Estate Consultant in India</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TemplateMob;
