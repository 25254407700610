import { FaChevronRight } from "react-icons/fa";
import { memo } from "react";
import { EMPTY_OBJECT } from "../../../../../assets/constants";

const CareerAccordionCard = memo(
  ({
    position,
    handleForm,
    setSelectedPosition,
    setSelectedPositionName,
    index,
    activeCard,
    setActiveCard,
  }) => {
    const { Position_Name, Position_Location, Position_Description } =
      position?.attributes || EMPTY_OBJECT;

    return (
      <div
        onClick={() => {
          setSelectedPosition(Position_Description);
          setSelectedPositionName(Position_Name);
          setActiveCard(index);
        }}
      >
        <div
          className={`d-flex w-100 justify-content-between border-bottom mb-2 card-item ${
            activeCard === index ? "activecards" : ""
          }`}
        >
          <div>
            <div style={{ fontSize: "18px", fontWeight: "600" }}>
              {Position_Name}
            </div>
            <div className="fs-6">{Position_Location}</div>
          </div>
          <div className="center-item">
            <FaChevronRight />
          </div>
        </div>
      </div>
    );
  }
);

export default CareerAccordionCard;
