import { useState } from "react";
import styles from "./masnory.module.css";

const MasonryCardSx = {
  display: "block",
  width: "100%",
  height: "100%",
};

const MasonryCard = ({ item, imgType }) => {
  const { Title, Media_Coverage_Image } = item?.item?.attributes;
  const size = parseInt(item?.size);
  const title = Title;
  const img = Media_Coverage_Image?.data?.attributes?.url;

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (!img || !title || !size) return null;

  // Function to open the popup
  const openPopupCard = () => {
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const closePopupCard = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      {/* Clickable Card */}
      <div className={styles.cardContainer} onClick={openPopupCard}>
        <img
          src={`${img}?w=162&auto=format`}
          alt={title}
          loading="lazy"
          style={MasonryCardSx}
        />
      </div>

      {/* Popup Modal */}
      {isPopupOpen && (
        <div className={styles.overlay} onClick={closePopupCard}>
          <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
            <button className={styles.closeButton} onClick={closePopupCard}>
              ✖
            </button>
            <img src={img} alt={title} className={styles.popupImage} />
          </div>
        </div>
      )}
    </>
  );
};

export default MasonryCard;
