import {
  checkTimeShift,
  getMonthName,
  splitDate,
} from "../../../../helpers/helper";
import EventTimeZone from "./EventTimeZone";
// import { SlCalender } from "react-icons/sl";
import { MdLocationOn } from "react-icons/md"; // Icon for Location
// import { BsClock } from "react-icons/bs";
import { IoMdTime } from "react-icons/io";

import "./eventLocationSummary.css";

const EventLocationSummary = ({ data }) => {
  const { Start_Date, Venue, Enter_The_Time, img } = data;
  const [year, month, day] = splitDate(Start_Date);

  const time = checkTimeShift(Enter_The_Time);
  const monthName = getMonthName(month).slice(0, 3);
  const date = `${day} ${monthName}, ${year}`;

  return (
    <div className="eventLocationSummary mt-5">
      <div>
        <img src={img} alt="Event" />
      </div>
      {/* Timings */}
      <div className="">
        <EventTimeZone
          icon={IoMdTime}
          title="Timings"
          text={date}
          time={time}
        />
        {/* Time */}
        {/* <EventTimeZone icon={""} text={time} /> */}
      </div>
      {/* Location */}

      <EventTimeZone icon={MdLocationOn} title="Location" text={Venue} isMap />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.836150711158!2d77.37507187616123!3d28.514576989426274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce44ec163255d%3A0x81c3efe80ef34cf5!2sExpress%20Trade%20Towers%202!5e0!3m2!1sen!2sin!4v1737564280958!5m2!1sen!2sin"
        width="100%"
        height="450px"
        style={{ border: "30px", marginBottom: "10px", marginRight: "20px" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};

export default EventLocationSummary;
