import { useMemo, useCallback, useState, useEffect } from "react";
import Heading from "../../../globalComponents/molecules/Heading";
import TextBg from "../../../globalComponents/molecules/TextBg";
import CareerFormPopUp from "./CareerFormPopUp";

// import { SORTED_BY_CAREER } from "../../../../assets/constants/filters";
// import { CAREER_POSITIONS } from "./../../../../assets/constants/career";
import CareerAccordionCardList from "./CareerAccordionCardList";
import { API_URL, getFullUrl } from "../../../../assets/constants/apiUrls";
import { getMethod } from "../../../../services";
import { EMPTY_ARRAY } from "../../../../assets/constants";
import "./position.css";

const POSITION_TABS = {
  ALL_BTN: "All",
};

const Position = () => {
  const [filter, setFilter] = useState(POSITION_TABS.ALL_BTN);
  const [positions, setPositions] = useState([]);
  const [isCareerFormOpen, setIsCareerFormOpen] = useState(false);
  const [careerModal, setCareerModal] = useState(null);

  // const [positions = [], error, isLoading] = useApiFetcher(
  //   "/api/career-positions?populate=*"
  // );

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const url = getFullUrl(`${API_URL.CAREER_POSITIONS}?populate=*`);
        const response = await getMethod(url);
        setPositions(response?.data);
      } catch (error) {
        console.error("Error fetching positions: ", error);
      }
    };

    fetchPositions();
  }, []);

  const departmentPositions = useMemo(() => {
    return filter === POSITION_TABS.ALL_BTN
      ? positions
      : positions.filter((position) =>
          position?.attributes?.career_department?.data?.attributes?.Department_Name.includes(
            filter
          )
        );
  }, [filter, positions]);

  const departmentsWithPositions = useMemo(
    () => groupPositionsByDepartment(positions),
    [positions]
  );

  const PositionButton = useMemo(() => {
    return [
      POSITION_TABS.ALL_BTN,
      ...new Set(
        Object.keys(departmentsWithPositions)
        // positions.map((position) => position.attributes.Position_Name)
      ),
    ];
  }, [departmentsWithPositions]);

  const handleJobForm = useCallback((selectedJobTitle) => {
    setIsCareerFormOpen((prevProps) => !prevProps);
    setCareerModal(selectedJobTitle);
  }, []);

  return (
    <div className="mb-5">
      {/* <TextBg
        text={"Open Positions"}
        fontWeight={800}
        fontSize="4rem"
        className="py-3"
      /> */}
      <Heading
        text={"Open Positions"}
        color={"black"}
        className="fw-bold mt-3 mb-4 text-center"
      />
      <div className="mt-4">
        <div className="filterTabs">
          {PositionButton.map((selectedBtn, idx) => {
            return (
              <button
                key={idx}
                onClick={() => setFilter(selectedBtn)}
                className={`departmentBtn ${
                  filter === selectedBtn && "departmentBtnActive"
                }`}
              >
                {selectedBtn}
              </button>
            );
          })}
        </div>
        <div className="row">
          {/* <div className="py-4">
            <span className="px-2 fs-4 border-bottom border-black">
              Positions
            </span>
          </div> */}

          <CareerAccordionCardList
            filteredPositions={departmentPositions}
            callBackHandleJobForm={handleJobForm}
          />

          {isCareerFormOpen && (
            <CareerFormPopUp
              isOpen={isCareerFormOpen}
              onClose={handleJobForm}
              formName={careerModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Position;

function groupPositionsByDepartment(positions = EMPTY_ARRAY) {
  return positions?.reduce((acc, position) => {
    const departmentName =
      position?.attributes?.career_department?.data?.attributes
        ?.Department_Name;

    if (!acc[departmentName]) {
      acc[departmentName] = [];
    }

    const { career_department, ...positionData } = position.attributes;
    acc[departmentName].push(positionData);

    return acc;
  }, {});
}
