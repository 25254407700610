import { useEffect, useState } from "react";

import CustomLink from "../../CustomLink";
import Heading from "../../globalComponents/molecules/Heading";
import HoverTemplateCard from "./HoverTemplateCard";

import { TEMPLATE_BUTTON_LIST } from "../../../assets/constant";
import styles from "./template.module.css";

const CITY_IMG = {
  BG_IMG2: "https://www.wealth-clinic.com/property_media/67581703666273.jpg",
  BG_IMG3:
    "https://png.pngtree.com/background/20210715/original/pngtree-night-view-of-high-rise-buildings-in-urban-district-picture-image_1312997.jpg",
};

const Template = () => {
  const { BG_IMG3 } = CITY_IMG;
  const [backGroundImg, setBackGroundImg] = useState();

  const handleMouseEnter = (buttonImg) => {
    setBackGroundImg(`${buttonImg}`);
  };

  const handleMouseLeave = () => {
    setBackGroundImg(BG_IMG3);
  };

  useEffect(() => {
    setBackGroundImg((prevImg) => (prevImg ? prevImg : BG_IMG3));
  }, [backGroundImg, BG_IMG3]);

  return (
    <div
      className={styles.backgroundImgContainer}
      style={{
        background: `rgba(0, 0, 0, 0.5) url(${backGroundImg})`,
        backgroundBlendMode: "darken",
        backgroundAttachment: "fixed",
      }}
    >
      <div
        className={`${styles.innerDiv} d-flex flex-column justify-content-center align-items-center mt-0`}
        style={{ minHeight: "60vh" }}
      >
        <div className="col-12 col-lg-8 text-center ">
          <Heading
            text={"Our Footprints"}
            className="center-item mb-4"
            color="#fff"
            fontWeight={800}
          />
          <p className="text-white">
            From coveted metros to growing suburbs, you’ll find us wherever you
            seek. We’re here to fulfil all your property aspirations be it a
            dream home for family, a second home for vacation, a vibrant shop
            for business, a dynamic office space for growth, or a farmland to
            build your own castle. Whatever you want…Wherever you want.
          </p>
        </div>

        {/* Buttons Container */}
        <div className={`${styles.buttonsContainer} d-flex flex-wrap  gap-1`}>
          {TEMPLATE_BUTTON_LIST?.map((ele, id) => {
            const { cityName, logo, img, img2 } = ele;
            return (
              <CustomLink
                to={`/${cityName}`}
                key={id}
                style={{ color: "#000" }}
                className="center-item  "
              >
                <HoverTemplateCard
                  imgOnHover={img2}
                  onMouseEnter={() => {
                    handleMouseEnter(img);
                  }}
                  onMouseLeave={handleMouseLeave}
                  cityName={cityName}
                />
              </CustomLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Template;
