import { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";

import CustomDropDown from "../globalComponents/customDropDown/CustomDropDown";
import DropDown from "../globalComponents/DropDown/DropDown";
import HeaderBtn from "../globalComponents/headerBtn/HeaderBtn";

import useApiFetcher from "../../hooks/useApiFetcher";
import { API_URL, generateSortedUrl } from "../../assets/constants/apiUrls";
import { SORTED_BY } from "../../assets/constants/filters";
import URL from "../../assets/constants/url";

const SORTED_CITY_PARAMS = {
  endPoint: API_URL.CITY_NAME,
  sortBy: "id",
  order: SORTED_BY.ASC,
};

const MobileSideBar = () => {
  const url = generateSortedUrl(SORTED_CITY_PARAMS);

  const [isScrollY, setIsScrollY] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [dropDownConfig, setDropDownConfig] = useState({
    cityName: false,
    findProperty: true,
  });

  const toggleMenu = () => setIsOpen(!isOpen);
  const handleScroll = () => setIsScrollY(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrollY]);

  const [cityName, error, isLoading] = useApiFetcher(url);
  if (error) return <span>{error.message}</span>;
  if (isLoading) return <span></span>;

  const CITY_NAME_1 = getCityName(cityName);
  return (
    <>
      <Navbar.Collapse
        id="basic-navbar-nav"
        className={`${isOpen ? "show" : ""}`}
      >
        <Nav className="ms-auto">
          <Nav.Link>
            <div className="d-flex flex-wrap">
              <i className="bi bi-geo-fill"></i>
              <DropDown
                styleBtn={{
                  color: "rgba(255,255,255,0.8)",
                  // width: "6rem",
                }}
                btnText={"Find Property"}
              />
            </div>
          </Nav.Link>
          <Nav.Link>
            <div className="d-flex mobNavBar flex-wrap">
              <div>
                <i className="bi bi-pin-map"></i>
              </div>
              <CustomDropDown
                onMouseEnter={() => setDropDownConfig({ cityName: true })}
                styleBtn={{
                  color: "rgba(255,255,255,0.8)",
                  // width: "6.5rem",
                }}
                styleMenu={{
                  width: "150px",
                  height: "150px !important",
                }}
                text={"Find City"}
                data={CITY_NAME_1}
                isDropDownOpen={dropDownConfig.cityName}
                setIsDropDownOpen={() =>
                  setDropDownConfig({
                    cityName: !dropDownConfig.cityName,
                  })
                }
                children={
                  <HeaderBtn
                    className={"navbar-brand"}
                    style={{
                      width: "100%",
                    }}
                  />
                }
              />
            </div>
          </Nav.Link>
          <Nav.Link href={URL.ABOUT} onClick={toggleMenu}>
            <i className="bi bi-person-circle"></i>
            About Us
          </Nav.Link>
          <Nav.Link href={URL.EVENT} onClick={toggleMenu}>
            <i className="bi bi-calendar"></i>
            Events
          </Nav.Link>
          {/* <Nav.Link href="#link" onClick={toggleMenu}>
            <i className="bi bi-person"></i>
            Talk To Our Agents
          </Nav.Link> */}
          <Nav.Link href={URL.BLOGS} onClick={toggleMenu}>
            <i className="bi bi-blockquote-left"></i>
            Blogs
          </Nav.Link>
          <Nav.Link href={URL.AWARD} onClick={toggleMenu}>
            <i className="bi bi-cast"></i>
            Awards
          </Nav.Link>
          <Nav.Link href={URL.HAPPY_CUSTOMER} onClick={toggleMenu}>
            <i className="bi bi-emoji-smile"></i>
            Happy Customer
          </Nav.Link>
          <Nav.Link href={URL.MEDIA_COVERAGE} onClick={toggleMenu}>
            <i className="bi bi-cast"></i>
            Media
          </Nav.Link>
          <Nav.Link href={URL.CAREER} onClick={toggleMenu}>
            <i className="bi bi-copy"></i>
            career
          </Nav.Link>
          <Nav.Link href={URL.NEWS} onClick={toggleMenu}>
            <i class="bi bi-newspaper"></i>
            News
          </Nav.Link>
          <Nav.Link href={URL.CONTACT_US} onClick={toggleMenu}>
            <i className="bi bi-phone"></i>
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </>
  );
};

export default MobileSideBar;

function getCityName(cityName) {
  return cityName?.map((item) => {
    // const data = { id: item?.id, City_Name: item?.attributes?.City_Name }
    return item?.attributes?.City_Name;
  });
}
