import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules"; // ✅ Import from swiper/modules
import "swiper/css";
import "swiper/css/autoplay";
import { FaTimes } from "react-icons/fa";

const VideoTestimonialSlider = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [showModal, setShowModal] = useState(false);

  const testimonials = [
    {
      id: 1,
      video: "https://www.youtube.com/embed/XJj2PbenIsU",
      thumbnail: "https://via.placeholder.com/300",
      name: "John Doe",
      review: "This course changed my life!",
    },
    {
      id: 2,
      video: "https://www.youtube.com/embed/abcd1234",
      thumbnail: "https://via.placeholder.com/300",
      name: "Jane Smith",
      review: "Highly recommended for beginners.",
    },
    {
      id: 3,
      video: "https://www.youtube.com/embed/xyz4567",
      thumbnail: "https://via.placeholder.com/300",
      name: "Alice Johnson",
      review: "Amazing experience!",
    },
    {
      id: 4,
      video: "https://www.youtube.com/embed/wxy7890",
      thumbnail: "https://via.placeholder.com/300",
      name: "Bob Brown",
      review: "I learned so much from this course!",
    },
  ];

  const openModal = (video) => {
    setVideoUrl(video);
    setShowModal(true);
  };

  const closeModal = () => {
    setVideoUrl("");
    setShowModal(false);
  };

  return (
    <></>
    // <div className="relative w-full max-w-4xl mx-auto">
    //   <Swiper
    //     spaceBetween={30}
    //     slidesPerView={1}
    //     autoplay={{ delay: 3000 }}
    //     loop={true}
    //     modules={[Autoplay]} // ✅ Use autoplay module here
    //     className="rounded-lg shadow-lg"
    //   >
    //     {/* Iterate over testimonials and create slides */}
    //     {testimonials.map((testimonial, index) => (
    //       <SwiperSlide key={testimonial.id}>
    //         <div className="grid grid-cols-2 gap-4">
    //           {/* Video 1 */}
    //           <div
    //             className="relative cursor-pointer"
    //             onClick={() => openModal(testimonial.video)}
    //           >
    //             <img
    //               src={testimonial.thumbnail}
    //               alt={testimonial.name}
    //               className="w-full h-64 object-cover rounded-lg"
    //             />
    //             <div className="absolute bottom-0 bg-black bg-opacity-50 text-white p-4 w-full text-center">
    //               <h3 className="text-lg font-bold">{testimonial.name}</h3>
    //               <p>{testimonial.review}</p>
    //             </div>
    //           </div>

    //           {/* Video 2 (if there's another video, you can add it similarly) */}
    //           {testimonials[index + 1] && (
    //             <div
    //               className="relative cursor-pointer"
    //               onClick={() => openModal(testimonials[index + 1]?.video)}
    //             >
    //               <img
    //                 src={testimonials[index + 1]?.thumbnail}
    //                 alt={testimonials[index + 1]?.name}
    //                 className="w-full h-64 object-cover rounded-lg"
    //               />
    //               <div className="absolute bottom-0 bg-black bg-opacity-50 text-white p-4 w-full text-center">
    //                 <h3 className="text-lg font-bold">
    //                   {testimonials[index + 1]?.name}
    //                 </h3>
    //                 <p>{testimonials[index + 1]?.review}</p>
    //               </div>
    //             </div>
    //           )}
    //         </div>
    //       </SwiperSlide>
    //     ))}
    //   </Swiper>

    //   {showModal && (
    //     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
    //       <div className="relative bg-white p-4 rounded-lg max-w-3xl w-full">
    //         <button
    //           className="absolute top-2 right-2 text-xl text-red-500"
    //           onClick={closeModal}
    //         >
    //           <FaTimes />
    //         </button>
    //         <iframe
    //           src={videoUrl}
    //           title="Testimonial Video"
    //           width="100%"
    //           height="400px"
    //           allowFullScreen
    //         ></iframe>
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};

export default VideoTestimonialSlider;
