import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState, lazy, Suspense } from "react";

import JsonLoad from "./components/globalComponents/seo/JsonLoad";
import Body from "./components/Body";
import TemplateSlider from "./components/homePage/templateSlider/TemplateSlider";
import HomePage from "./components/homePage/HomePage";
import CityPages from "./components/pages/cityPages/CityPages";
import Gallery from "./components/pages/gallery/Gallery";
import MediaCoverage from "./components/pages/mediaCoverage/MediaCoverage";
import HappyCustomer from "./components/pages/happyCustomer/HappyCustomer";
import Awards from "./components/pages/award/Awards";
import ContactUsPage from "./components/pages/ContactUsPage/ContactUsPage";
import Career from "./components/pages/career/Career";

import Event from "./components/pages/event/Event";
import PrivacyPolicy from "./components/pages/privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/pages/termsAndConditions/TermsAndConditions";
import OurTeam from "./components/pages/ourTeam";
import About from "./components/pages/about/About";
import EventOverview from "./components/pages/eventOverview/EventOverview";
// import Blog from "./components/pages/blogPage/Blogs";
import BlogCards from "./components/pages/blogPage/blogsComponents/BlogCards";
import DisclaimerPage from "./components/pages/disclaimer/DisclaimerPage";
import BlogOverview from "./components/pages/blogOverview/BlogOverview";
// import PropertyDetails from "./components/pages/propertyDetails/PropertyDetails";
import EmiCalculatorPage from "./components/pages/EmiCalculatorPage";
import ProjectDetailsPage from "./components/pages/ProjectDetailsPage";
// import CustomMetaTags from "./components/CustomMetaTags/CustomMetaTags";

import HomePageMobile from "./components/mobileComponents/page/HomePages";
import TemplateMobile from "./components/mobileComponents/page/template/TemplateMob";
import NotFoundPage from "./components/pages/NotFoundPage";

// import AboutUsPage from "./components/pages/AboutUsPage/AboutUsPage";
// import Header from "./components/globalComponents/header/Header";
// import ProtectedRoute from "./components/globalComponents/ProtectedRoute/ProtectedRoute";
// import Template from "./components/template/Template";
// import Header from "./components/header/Header";
// import HeaderMobile from "./components/mobileComponents/HeaderMob";
// import TopNavbar from "./components/mobileComponents/page/TopNavbar";

// import useGoogleTagManager from "./hooks/useGoogleTagManager";
import useDeviceSize from "./hooks/useDeviceSize";
import useUrlNormalizer from "./hooks/useUrlNormalizer";
import { slugToText } from "./services/slug";
import URL from "./assets/constants/url";
// import TEMPLATE_BACKGROUND from "./templateBackground.webp";
import TEMPLATE_BACKGROUND from "../src/components/homePage/templateSlider/templateBackground.webp";
// import Events from "./assets/Events.mp4";
import Blogs from "../src/assets/Blogs.jpg";
import Contactus from "../src/assets/Contactus.jpg";
import "./components/styles/mobile-global.css";
import "../src/components/styles/global.css";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsPage from "./components/pages/newspage/NewsPage";
import carrer from "./assets/carrer.jpg";
import Happy from "./assets/HappyCustomer.jpg";
import Abouts from "./assets/All_Directors_Team_ddb3d525b1.jpg";
import termsAndConditions from "./assets/terms-conditions.png";
// import Template from "./components/homePage/template/Template";
const Blog = lazy(() => import("./components/pages/blogPage/Blogs"));
const GoogleTagManager = lazy(() => import("./components/GoogleTagManager"));

function App() {
  const isMobile = useDeviceSize();

  // useGoogleTagManager();

  return (
    <>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="z0gy6j7phcx4ial5xdae7fza5tyk7n"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {/* <CustomMetaTags /> */}
      <Provider store={store}>
        <BrowserRouter>
          <GoogleTagManager />
          <JsonLoad />
          <Routings isMobile={isMobile} />
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;

function Routings({ isMobile }) {
  const PAGE_NOT_FOUND_ROUTES = [
    "/404",
    "*",
    "/public",
    "/public/:detail",
    "/public/detail",
    "/public/detail/:cityName",
  ];

  const REDIRECT_TO_URL = [
    {
      path: "/blogs",
      redirect: "/blog",
    },
    // {
    //   path: "/public/detail/:cityName/:propertyName",
    //   redirect: `/:${cityName}/:${propertyName}`,
    // },
    {
      path: "/public/career",
      redirect: "/career",
    },
  ];
  const location = useLocation();
  useUrlNormalizer(location?.pathname);
  // const [user, setUser] = useState(false);
  const [city, setCityName] = useState(null);
  const Events =
    "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/Events.mp4";

  return (
    <>
      <Routes>
        <Route
          path={URL.HOME}
          element={<Body>{isMobile ? <HomePageMobile /> : <HomePage />}</Body>}
        />
        <Route
          path={URL.CITY}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text={
                    city ? "New Projects in " + slugToText(city) : "Properties"
                  }
                  height="380px"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider
                  text={
                    city ? "New Projects in " + slugToText(city) : "Properties"
                  }
                  height="70vh"
                  searchBar="enabled"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              )}
              <CityPages setCityName={setCityName} />
            </Body>
          }
        />
        <Route
          path={`${URL.SEARCH}/:propertyName`}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Properties"
                  height="70vh"
                  searchBar="enabled"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider
                  text="Properties"
                  height="100vh"
                  searchBar="enabled"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              )}
              <CityPages />
            </Body>
          }
        />
        <Route
          path={URL.SEARCH}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Properties"
                  height="100vh"
                  searchBar="enabled"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider
                  text="Properties"
                  height="70vh"
                  searchBar="enabled"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              )}
              <CityPages />
            </Body>
          }
        />
        {/* <Route
          path={URL.BLOGS}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile text="Blogs" />
              ) : (
                <TemplateSlider text="Blogs" height="100vh" />
              )}
              <Suspense fallback={<div>Loading Blog...</div>}>
                <Blog>
                  <BlogCards />
                </Blog>
              </Suspense>
            </Body>
          }
        />
        <Route
          path={URL.BLOG_SUMMARY_PAGE}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile text="Blogs" height="100vh" />
              ) : (
                <TemplateSlider text="Blogs" height="100vh" />
              )}
              <Blog>
                <BlogOverview />
              </Blog>
            </Body>
          }
        /> */}
        <Route
          path={URL.BLOGS}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile text="Blogs" bgImage={Blogs} />
              ) : (
                <TemplateSlider text="Blogs" height="70vh" bgImage={Blogs} />
              )}
              <Suspense fallback={<div>Loading Blog...</div>}>
                <Blog />
              </Suspense>
            </Body>
          }
        >
          <Route index element={<BlogCards />} />
          <Route path={URL.BLOG_SUMMARY_PAGE} element={<BlogOverview />} />
        </Route>
        <Route
          path={URL.ABOUT}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="About Us"
                  height="100vh"
                  bgImage={Abouts}
                />
              ) : (
                <TemplateSlider
                  text="About Us"
                  height="70vh"
                  bgImage={Abouts}
                />
              )}
              <About />
            </Body>
          }
        />
        <Route path="/wcgallery" element={<Gallery />} />
        <Route
          path={URL.NEWS}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="News"
                  height="100vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider
                  text="News"
                  height="70vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              )}
              <NewsPage />
            </Body>
          }
        />
        <Route
          path={URL.AWARD}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Awards"
                  height="100vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider
                  text="Awards"
                  height="70vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              )}
              <Awards />
            </Body>
          }
        />
        <Route
          path={URL.CONTACT_US}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Contact Us"
                  height="100vh"
                  bgImage={Contactus}
                />
              ) : (
                <TemplateSlider
                  text="Contact Us"
                  height="70vh"
                  bgImage={Contactus}
                />
              )}
              <ContactUsPage />
            </Body>
          }
        />
        <Route
          path={URL.HAPPY_CUSTOMER}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile text="Happy Customers" bgImage={Happy} />
              ) : (
                <TemplateSlider
                  text="Happy Customers"
                  height="70vh"
                  bgImage={Happy}
                />
              )}
              <HappyCustomer />
            </Body>
          }
        />
        <Route
          path={URL.MEDIA_COVERAGE}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Media Coverage"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider
                  text="Media Coverage"
                  height="70vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              )}
              <MediaCoverage />
            </Body>
          }
        />
        {/* <Route
          path={URL.PROPERTY_DETAILS}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile text="Project Details" height="100vh" />
              ) : null}
              <PropertyDetails />
            </Body>
          }
        /> */}
        -----------------
        <Route
          path={URL.PROPERTY_DETAILS}
          element={
            <Body>
              {isMobile ? <ProjectDetailsPage /> : <ProjectDetailsPage />}
            </Body>
          }
        />
        -----------------
        <Route
          path="/event//*"
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile text="Events" height="100vh" bgImage={Events} />
              ) : (
                <TemplateSlider text="Events" height="70vh" bgImage={Events} />
              )}
              <Event />
            </Body>
          }
        />
        <Route
          path={URL.PRIVACY_POLICY}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Privacy-Policy"
                  height="100vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider
                  text="Privacy-Policy"
                  height="70vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              )}
              <PrivacyPolicy />
            </Body>
          }
        />
        <Route
          path={URL.DISCLAIMER}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Disclaimer"
                  height="100vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider
                  text="Disclaimer"
                  height="70vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              )}
              <DisclaimerPage />
            </Body>
          }
        />
        <Route
          path={URL.CAREER}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile text="Career" height="100vh" bgImage={carrer} />
              ) : (
                <TemplateSlider text="Career" height="70vh" bgImage={carrer} />
              )}
              <Career />
            </Body>
          }
        />
        <Route
          path={URL.OUR_TEAM}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Our Team"
                  height="100vh"
                  bgImage={Abouts}
                />
              ) : (
                <TemplateSlider
                  text="Our Team"
                  height="70vh"
                  bgImage={Abouts}
                />
              )}
              <OurTeam />
            </Body>
          }
        />
        <Route
          path={URL.TERMS_AND_CONDITIONS}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Terms And Conditions"
                  height="25rem"
                  bgImage={termsAndConditions}
                />
              ) : (
                <TemplateSlider
                  text="Terms And Conditions"
                  height="70vh"
                  bgImage={termsAndConditions}
                />
              )}
              <TermsAndConditions />
            </Body>
          }
        />
        <Route
          path={URL.EVENT_STATUS_AND_NAME}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="Events"
                  height="25rem"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider text="Events" height="70vh" bgImage={Events} />
              )}
              <EventOverview />
            </Body>
          }
        />
        <Route
          path={URL.EMI_CALCULATOR}
          element={
            <Body>
              {isMobile ? (
                <TemplateMobile
                  text="EMI Calculator"
                  height="25rem"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              ) : (
                <TemplateSlider
                  text="EMI Calculator"
                  height="70vh"
                  bgImage={TEMPLATE_BACKGROUND}
                />
              )}
              <EmiCalculatorPage />
            </Body>
          }
        />
        {PAGE_NOT_FOUND_ROUTES?.map((routesPath) => (
          <Route
            key={routesPath}
            path={routesPath}
            element={
              <Body>
                <NotFoundPage />
              </Body>
            }
          />
        ))}
        {REDIRECT_TO_URL?.map((routeData, idx) => {
          return (
            <Route
              key={routeData?.path + idx}
              path={routeData?.path}
              element={<Navigate to={routeData?.redirect} replace />}
            />
          );
        })}
        <Route
          path="/new-page"
          element={
            <Body>
              <ProjectDetailsPage />
            </Body>
          }
        />
        {/* <Route path="/hello" element={<Navigate to="/noida" replace />} /> */}
      </Routes>
    </>
  );
}
