import { Radio } from "@mui/material";
import { useState } from "react";

import CustomLink from "../../CustomLink";
import { FindPropertyDropDownBtn } from "../headerBtn/HeaderBtn";

import { getPropertyType } from "./filter.helper";
import { SORTED_BY_PROPERTY_TYPE } from "../../../assets/constants/filters";
import styles from "./dropDown.module.css";

const BUTTON_DATA = [
  SORTED_BY_PROPERTY_TYPE.RESIDENTIAL,
  SORTED_BY_PROPERTY_TYPE.COMMERCIAL,
];

const DropDown = ({
  children,
  listStyle,
  styleBtn,
  // getValue: setValue,
  btnText,
  childrenBtn,
}) => {
  console.log('====================================');
  console.log('====================================');
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isNestedDropDownTypeOpen, setIsNestedDropDownTypeOpen] =
  useState(true);
  
  const [radioBtnValue, setRadioBtnValue] = useState(null);
  const [nested, setNested] = useState(false);
  const handleCloseDropDown = () => {
    setRadioBtnValue(null);
    setIsDropDownOpen(false);
  };
  console.log('>>>>>>>>>', {BUTTON_DATA, btnText, radioBtnValue});
  // console.log("nested", nested);

  return (
    <div
      className={`${styles.dropDown}`}
      onMouseEnter={() => {
        setIsDropDownOpen(true);
        setNested(false);
      }}
      onMouseLeave={handleCloseDropDown}
    >
      <div className={styles.dropDownPad}>
        <div
          className={`${styles.dropDownBtn}`}
          style={styleBtn}
          onClick={() => {
            setIsNestedDropDownTypeOpen(true);
            setIsDropDownOpen(!isDropDownOpen);
          }}
        >
          <div>{btnText || "Type"}</div>
          <div className="dropDownArrow">
            <i
              className={`fa fa-chevron-right icon ms-2 ${
                isDropDownOpen && "open"
              }`}
            ></i>
          </div>
        </div>

        {isDropDownOpen && (
          <div className="position-absolute top-100 pt-2">
            <div className={styles.dropDownList}>
              <DropDownList
                data={BUTTON_DATA}
                setValue={setRadioBtnValue}
                setNested={setNested}
              />
              {/* {isNestedDropDownTypeOpen && children} */}
              {/* <div>Unit Type</div> */}
              <div className={`position-absolute start-100 top-0 ps-1`}>
                <div
                  className={`${styles.nestedList} ${
                    nested && "bg-white"
                  } rounded mt-3`}
                >
                  <ul>
                    {getPropertyType(radioBtnValue)?.map((type, idx) => (
                      <li key={idx}>
                        <FindPropertyDropDownBtn
                          className={styles.propertyDropDownBtn}
                          text={type}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;

export function DropDownList({ data, setValue, setNested }) {
  const [checkedValue, setCheckedValue] = useState("");
  console.log('vvvvvvvvvvvvvvvv', data);
  const handleRadioChange = (e) => {
    setCheckedValue(e.target.value);
    if (setValue) {
      setValue(e.target.value);
    }
  };

  return (
    <>
      {data?.map((value, idx) => (
        <div
          key={idx}
          className={`d-flex justify-content-between  text-decoration-lg-underline  gap-4  ${styles.dropDownListBtn}`}
          onMouseEnter={() => {
            setCheckedValue(value);
            setValue(value);
            setNested(true);
          }}
        >
          <div>{value}</div>
          <div className="">
            {/* <Radio
              size="small"
              sx={{ color: "#ef750f" }}
              className={styles.radioBoxBtn}
              value={value}
              checked={checkedValue === value}
              onChange={handleRadioChange}
            /> */}
            <i class="bi bi-chevron-right"></i>
          </div>
        </div>
      ))}
      <CustomLink
        to={"/search?building-type=Second Home"}
        className={`d-flex ${styles.dropDownListBtn} `}
        onClick={() => {
          setCheckedValue("Second Home");
          setValue("Second Home");
          setNested(true);
        }}
      >
        <div className="text-black">{"Second Home"}</div>
        <div className="">
          {/* <Radio
            size="small"
            sx={{ color: "#ef750f" }}
            className={styles.radioBoxBtn}
            value={"Second Home"}
            checked={checkedValue === "Second Home"}
            onChange={handleRadioChange}
          /> */}
        </div>
      </CustomLink>
    </>
  );
}

// export const DropDown2 = () => {
//   const [isDropDownOpen, setIsDropDownOpen] = useState(false);
//   const [activeItem, setActiveItem] = useState(null);

//   const items = [
//     { label: "Commercial", key: "commercialData" },
//     { label: "Residential", key: "residential" },
//   ];

//   const handleItemClick = (key) => {
//     setActiveItem(activeItem === key ? null : key);
//   };

//   return (
//     <div className={styles.dropDown}>
//       <div
//         onClick={() => {
//           setIsDropDownOpen(!isDropDownOpen);
//         }}
//       >
//         {activeItem || "Select Typeee"}
//       </div>
//       {isDropDownOpen && (
//         <ul className={styles.dropDownList}>
//           {items.map((item) => (
//             <li key={item.key} onClick={() => handleItemClick(item.key)}>
//               {item.label}
//             </li>
//           ))}
//         </ul>
//       )}
//       {activeItem === "commercialData" && <div>Commercial Dataaa</div>}
//       {activeItem === "residential" && <div>Residentialll</div>}
//     </div>
//   );
// };

//  <DropDown
// children={
//   <CustomDropDown
//     data={BUILDING_TYPE}
//     styleMenu={{ width: "164px" }}
//     text={"Type"}
//     isDropDownOpen={dropDownConfig.selectType}
//     setIsDropDownOpen={() =>
//       setDropDownConfig({
//         cityName: false,
//         selectType: !dropDownConfig.selectType,
//         budget: false,
//       })
//     }
//     children={<SearchDropDownButtons />}
//     dropDownValueKeyName={keys[1]}
//     setDropDownValue={setDropDownValue}
//   />
// }
// />

// -------------------

// const categories = [
//   { label: "Commercial", value: "commercial" },
//   { label: "Residential", value: "residential" },
//   { label: "Industrial", value: "industrial" },
// ];

// if come

// export function NestedList() {
//   return;
// }

// radioBtnValue
