import Slider from "react-slick";

import useApiFetcher from "../../../hooks/useApiFetcher";
import { extractImageAttributes } from "../../../helpers/extractAttributes";
import { getFullUrl } from "../../../assets/constants/apiUrls";
import { EMPTY_OBJECT } from "../../../assets/constants";
import TEMPLATE_BACKGROUND from "./templateBackground.webp";
// import VIDEO_URL from "../../../assets/videourl.mp4";
import abouts from "../../../assets/All_Directors_Team_ddb3d525b1.jpg";
import "./newTemplateSlider.css";

const TemplateCard = ({ imgHeight }) => {
  const [homePageData, isLoading] = useApiFetcher(
    getFullUrl("/api/home-pages?populate=*")
  );

  if (!homePageData || isLoading) {
    return (
      <div className="templateSliderContainer">
        <div className="">
          <img
            // className="img-fluid"
            alt={"wealth-clinic"}
            title={"wealth clinic template"}
            src={`${abouts}`}
            loading="lazy"
          />
        </div>
      </div>
    );
  }
  const API_IMAGES = extractImageAttributes(
    homePageData[0]?.attributes?.templates?.data
  );

  const settings = {
    dots: false,
    infinite: API_IMAGES.length > 1 && true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  return (
    <div className="templateSliderContainer">
      {/* <Slider {...settings} className="">
        {API_IMAGES.map((image, idx) => {
          const {
            img = TEMPLATE_BACKGROUND,
            alt = "",
            title = "",
          } = image || EMPTY_OBJECT;

          return (
            <div key={idx} className="h-100">
              <img
                className="img-fluid"
                alt={alt}
                title={title}
                src={`${img}`}
                loading="lazy"
                style={{ height: imgHeight || "100vh" }}
              />
              <video className="" autoPlay loop muted>
                <source src={VIDEO_URL} type="video/mp4" />
              </video>
            </div>
          );
        })}
      </Slider> */}

      <video
        // className="h-100vh"
        // style={{ height: "100vh" }}
        autoPlay
        loop
        muted
        loading="lazy"
        preload="auto"
        poster={abouts}
      >
        <source
          src={
            "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/videourl.mp4"
          }
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default TemplateCard;
