// import TextBg from "../../globalComponents/molecules/TextBg";
import Heading from "../../globalComponents/molecules/Heading";
import useApiFetcher from "../../../hooks/useApiFetcher";
import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
// import { CUSTOMER_VIDEO_URL } from "../../../assets/constant/happyCustomer";
import RecentPost from "../blogPage/blogsComponents/RecentPost";
import RecentProperty from "../blogPage/blogsComponents/RecentProperty";
import QuerySectionTemplate from "../../homePage/querySectionTemplate/QuerySectionTemplate";

import { getYoutubeEmbedUrl } from "../../../services/youtubeLinkEmbedConverter";
import { EMPTY_OBJECT } from "../../../assets/constants";
import "./happyCustomer.css";

const url = getFullUrl(`${API_URL.HAPPY_CUSTOMER}${API_URL.POPULATE}`);

const HappyCustomer = () => {
  const [happyCustomer, error, isLoading] = useApiFetcher(url);

  if (error) return <div>{error.message}</div>;
  if (!happyCustomer && isLoading) return null;

  const CUSTOMER_VIDEO_URL = happyCustomer;

  let ytUrl;
  if (CUSTOMER_VIDEO_URL) {
    ytUrl = happyCustomer[0]?.attributes?.YouTube_link || EMPTY_OBJECT;
  }

  return (
    <div className="container-fluid">
      <Heading
        text={"Reviews"}
        color={"black"}
        className="fw-bold mt-5 mb-4 text-center"
      />
      {/* <TextBg  fontWeight={700} fontSize="4rem" /> */}

      <div className="row justify-content-lg-around">
        {/* <div className="col-md-1"></div> */}
        <div className="col-lg-8 col-md-8">
          <div className="carousel">
            <div className="">
              <iframe
                title="YouTube Video"
                src={getYoutubeEmbedUrl(ytUrl) || ""}
                allowFullScreen
                name="slider1"
              />
            </div>
            <span className="scroll_x">
              {CUSTOMER_VIDEO_URL?.map((item, idx) => (
                <Testimonials key={idx} item={item} />
              ))}
            </span>
          </div>
        </div>

        <div className="col-lg-3 col-md-3">
          <RecentPost />
          <RecentProperty />
        </div>
        <QuerySectionTemplate />
      </div>
    </div>
  );
};

export default HappyCustomer;

export function Testimonials({ item }) {
  const { YouTube_link = "", YouTube_Thumbnail = "" } =
    item?.attributes || EMPTY_OBJECT;
  const { url = "" } = YouTube_Thumbnail?.data?.attributes || EMPTY_OBJECT;
  const [url1, imgSrc] = [YouTube_link, url];

  return (
    <a href={getYoutubeEmbedUrl(url1)} target="slider1">
      <img alt="" src={imgSrc} />
    </a>
  );
}
